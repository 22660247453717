<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-body">
                <div class="example">
                    <!--    Bộ lọc cố định            -->
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <el-select @change="getCustomer()" v-model="filter" clearable filterable
                                           class="w-100" collapse-tags
                                           placeholder="Lọc danh mục">
                                    <el-option
                                         v-for="item in filters"
                                         :key="item.value"
                                         :label="item.label"
                                         :value="item.value"
                                    >
                                        <span style="float: left">{{ item.label }}</span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <el-col v-loading="loading" :span="24">
                            <div class="example col-md-12">
                                <div class="example-preview table-responsive overflow-auto mb-15">
                                    <table class="table table-vertical-center">
                                        <thead>
                                        <tr>
                                            <th scope="col">Danh mục</th>
                                            <th scope="col">Nội dung chi tiết</th>
                                            <th scope="col">Người làm</th>
                                            <th scope="col">Thời gian</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(item, index) in data" :key="index">
                                            <td>{{ item.danh_muc }}</td>
                                            <td>
                                              <span>{{ item.content }}</span>
                                              <br/>
                                              <div style="display: flex; align-items: start; gap: 3px" v-if="item.note">
                                                <span style="white-space: nowrap">Ghi chú:</span> <span v-html="item.note"></span>
                                              </div>
                                            </td>
                                            <td>{{ item.nguoi_lam ? (Number(item.nguoi_lam.id) + 100000) + ' - ' + item.nguoi_lam.name : '' }}</td>
                                            <td>{{ item.ngay_tao }}</td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </el-col>
                    </div>
                    <div class="row">
                        <div style="display: flex;justify-content: center" class="col-md-12 edu-paginate mx-auto text-center">
                            <paginate
                                 v-model="page"
                                 :page-count="last_page"
                                 :page-range="3"
                                 :margin-pages="2"
                                 :click-handler="clickCallback"
                                 :prev-text="'Trước'"
                                 :next-text="'Sau'"
                                 :container-class="'pagination b-pagination'"
                                 :pageLinkClass="'page-link'"
                                 :page-class="'page-item'"
                                 :next-link-class="'next-link-item'"
                                 :prev-link-class="'prev-link-item'"
                                 :prev-class="'page-link'"
                                 :next-class="'page-link'"
                            >
                            </paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {HISTORY_CRM} from "../../../core/services/store/customer/customer-crm.module";
export default {
    name: "CrmHistory",
    data() {
        return {
            filters: [
                {
                    label: 'Kinh doanh',
                    value: 'kinh_doanh'
                },
                {
                    label: 'Tài chính',
                    value: 'tai_chinh'
                },
                {
                    label: 'Học tập',
                    value: 'hoc_tap'
                },
                {
                    label: 'Chăm sóc khách hàng',
                    value: 'cham_soc_khach_hang'
                },
            ],
            filter: '',
            page: 1,
            last_page: 1,
            loading: false,
            data: []
        }
    },
    mounted() {
        this.getCustomer();
    },
    methods: {
        clickCallback(obj) {
            this.page = obj;
            this.getCustomer({  page: this.page});
        },
        getCustomer(obj = {}) {
            let payload = { ...obj, ...{ id : this.$route.params.id, filter: this.filter }};
            this.loading = true;
            this.$store.dispatch(HISTORY_CRM, payload).then(res => {
                if (!res.error) {
                    this.data = res.data;
                    this.last_page = res.pagination.last_page;
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>
